<template>
    <zw-sidebar @shown="shown" :title="$t('offering.title.return')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="12">
                        <zw-checkbox-group name="positions"
                                           :label-prefix="labelPrefix"
                                           v-model="form.positions"
                                           text-field="name"
                                           value-field="item"
                                           size="sm"
                                           :options="returnPositions"
                                           :validate="'required'"
                        ></zw-checkbox-group>
                    </b-col>

                    <b-col cols="12">
                        <zw-select-group v-model="form.condition"
                                         :options="['A','B','C','D','E']"
                                         name="condition"
                                         :label-prefix="labelPrefix"
                        ></zw-select-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-switch-group v-model="form.reject"
                                         name="reject"
                                         :label-prefix="labelPrefix"
                        ></zw-switch-group>
                    </b-col>
                    <b-col v-if="form.reject=='1'" cols="12">
                        <zw-input-group v-model="form.reason"
                                        name="reason"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'ReturnPositionModal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            maxCount: 0,
            returnPositions:[],
            form: {
                id: null,
                reject: '0',
                reason: '',
                positions: []
            },
            labelPrefix: 'offering.return.label.',
        }
    },
    methods: {
        ...mapGetters('Offering', ['getReturnPosition']),
        shown() {
            this.form.id = this.payload.id
            this.maxCount = this.payload.count
            this.$store.dispatch('Offering/returnPositionData', this.payload.id)
                .then(() => {
                    let options = []
                    this.getReturnPosition().api_positions.forEach(value => {
                        options.push({ item: value, name: value, disabled: ( this.getReturnPosition().api_positions_returned.includes(value)) })
                    })
                    this.returnPositions = options
                    this.loading = false
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid && this.form.positions.length <= this.maxCount) {
                    this.loading = true;
                    this.$store.dispatch('Offering/returnPosition', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    },
}
</script>